$button: #007bff;
$offwhite: #f8f9fa;
$green: #4cb034;
@use 'mediaQueries.modules.scss';

.blue{
    color: $button;
}
.green{
    color: $green;
}

.btn{
                background-color: $green;
                color: white;
                width: fit-content;
                text-decoration: none;
                cursor: pointer;
                padding: 15px 25px;
                border: none;
            }
.landing-container{
    background-image: url('../img/workers.jpg');
    background-repeat: no-repeat;
    background-position: top right;
    background-size: cover;
    width: 100%;
    height: 700px;
    @include mediaQueries.for-phone-only; @include mediaQueries.for-tablet-landscape-up{
        // background: none;
        height: 400px;
        // background-size: 150%;
        // background-color: #f7f7f7;
    }
    .landing-container-content{
        width: 60%;
        background-color: white;
        overflow: visible;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 50px;
        clip-path: polygon(0% 0%, 0% 100%, 30% 100% ,90% 0%);
        @include mediaQueries.for-phone-only; @include mediaQueries.for-tablet-landscape-up{
            clip-path: none;
            width: calc(100% - 20px);
            background-color: rgba($color: #000000, $alpha: 0.4);
            padding:0 10px;
        }
        div{
            width: 500px;
            position: relative;
            bottom: 100px;
            @include mediaQueries.for-phone-only; @include mediaQueries.for-tablet-landscape-up{
                width: 100%;
                bottom: 0;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
            h1{
                text-transform: uppercase;
                font-size: 50px;
                @include mediaQueries.for-phone-only; @include mediaQueries.for-tablet-landscape-up{
                    color: white;
                    font-size: x-large;
                    text-align: center;
                }
            }
            p{
                color: gray;
                width: 400px;
                @include mediaQueries.for-phone-only; @include mediaQueries.for-tablet-landscape-up{
                    color: white;
                    width: 100%;
                    text-align: center;
                }
            }
            
        }


    }
}
.first-container, .third-container{
    background-color: white;
    height: fit-content;
    margin-top: 100px;
    position: relative;
    padding: 100px 50px;
    // padding-left: 50px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    @include mediaQueries.for-tablet-landscape-up{
        align-items: center;
        padding: 10px 50px;
    }
    @include mediaQueries.for-phone-only{
        width: calc(100% - 30px);
        padding: 0 15px;
    }
    .background{
        background-image: url('../img/dl.beatsnoop.com-high-62635505855f1ec1d7.jpg');
        position: absolute;
        width: 750px;
        clip-path: polygon(0% 0%, 90% 0%, 100% 100%, 0 90%);
        height: 750px;
        border: none;
        top: 50px;
        left: 0;
        @include mediaQueries.for-phone-only; @include mediaQueries.for-tablet-landscape-up{
            display: none;
        }
    }
    >div{
        width: 40%;
        z-index: 2;
        height: 600px;
        &:nth-of-type(2){
            width: 50%;
            background-image: url('../img/worker1.jpg');
            background-size: cover;
            background-position: right;
            @include mediaQueries.for-tablet-landscape-up{
                height: 500px;
                // margin-top: 50px;
            }
            @include mediaQueries.for-phone-only{
                width: 100%;
                height: 400px;
            }
        }
        &:nth-of-type(3){
            height: min-content;
            padding: 0 20px;
            @include mediaQueries.for-tablet-landscape-up{
                padding-right: 0;
                width: calc(50% - 20px);
            }
            @include mediaQueries.for-phone-only{
                padding: 0;
                width: 100%;
            }
            h1{
                margin-top: 0;
                font-size: 50px;
                @include mediaQueries.for-tablet-landscape-up{
                    font-size: x-large;
                }
                @include mediaQueries.for-phone-only{
                    font-size: x-large;
                    margin-top: 20px;
                }
            }
            p{
                text-align: left;
                color: gray;
                @include mediaQueries.for-phone-only{
                    
                }
            }
            ul{
                padding: 0;
                padding-top: 50px;
                @include mediaQueries.for-tablet-landscape-up{
                    padding-top: 10px;
                }
                @include mediaQueries.for-phone-only{
                    padding-top: 20px;
                }
                
                >p{
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-start;
                    img{
                        width: 50px;
                        @include mediaQueries.for-phone-only{
                            width: 40px;
                        }
                    }
                    div{
                        width: 80%;
                        @include mediaQueries.for-tablet-landscape-up{
                            width: 82%;
                        }
                        @include mediaQueries.for-phone-only{
                            width: 85%;
                        }
                        h1{
                            font-size: x-large;
                            color: rgb(12, 3, 46);
                            @include mediaQueries.for-phone-only{
                                margin-top: 0;
                                font-weight: 600;
                            }
                        }
                        p{
                            font-size: small;
                            @include mediaQueries.for-phone-only{
                                line-height: 1.5;
                                font-size: medium;
                            }
                        }
                    }
                }
            }
        }
    }
    &.third-container{
        flex-direction: row-reverse;
        @include mediaQueries.for-phone-only{
            flex-direction: column-reverse;
        }
        .background{
            background-image: url('../img/dl.beatsnoop.com-high-62635505855f1ec1d7.jpg');
            position: absolute;
            width: 700px;
            // clip-path: polygon(0% 0%, 90% 0%, 100% 100%, 0 90%);
            clip-path: polygon(10% 0%, 0% 100%, 100% 90%, 100% 0%);
            height: 750px;
            border: none;
            top: 50px;
            left: initial;
            right: 0;
        }
        >div{
            width: 50%;
            z-index: 2;
            height: 600px;
            &:nth-of-type(2){
                width: 40%;
                background-image: url('../img/worker2.jpg');
                background-size: cover;
                background-position: right;
                @include mediaQueries.for-tablet-landscape-up{
                    width: 45%;
                    height: 400px;
                }
                @include mediaQueries.for-phone-only{
                    width: 100%;
                }
            }
            &:nth-of-type(3){
                @include mediaQueries.for-tablet-landscape-up{
                    padding-left: 0;
                    width: 50%;
                    height: fit-content;
                }
                @include mediaQueries.for-phone-only{
                    padding: 0;
                    height: 500px;
                    width: 100%;
                }
                .YOE{
                    width: 300px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    .years{
                        height: fit-content;
                        width: 150px;
                        span{
                            font-size: 120px;
                            height: fit-content;
                            color: $green;
                            font-weight: 600;
                            margin: 0;
                        }
                    }
                    .experience{
                        border-left: 1px solid black;
                        height: 100px;
                        padding-left: 20px;
                        width: 130px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        font-size: large;
                        font-weight: 600;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }
}

.our-services, .our-projects{
    padding: 50px 50px;
    width: calc(100% - 100px);
    @include mediaQueries.for-phone-only{
        width: calc(100% - 30px);
        padding: 50px 15px;
        padding-bottom: 0;
    }
   
    h1{
        color: #007bff;
        font-size: 40px;
        text-transform: capitalize;
        @include mediaQueries.for-tablet-landscape-up{
            font-size: larger;
        }
        @include mediaQueries.for-phone-only{
            font-weight: 600px;
        }
    }
    >h1{
            color: black;
            margin-bottom: 50px;
            // text-decoration: underline $button;
            text-align: center;
            // text-shadow: 4px 4px 3px rgba(128, 128, 128, 0.242);
    }
    .card-row{
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;
        min-height: 400px;
        >div{
            width: calc(31% - 40px);
            height: max-content;
            border: 0.5px dotted gray;
            transition: 0.5s;
            margin: 0;
            margin-right: 2.2%;
            margin-bottom: 25px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
            padding: 50px 20px;
            // padding-top: 50px;
            transition: 0.5s;

            @include mediaQueries.for-tablet-landscape-up{
                height: max-content;
                width: calc( 48% - 40px);
                margin-right: auto;
                margin-bottom: 25px;
            }
            @include mediaQueries.for-phone-only{
                width: 100%;
                margin-right: 0;
                height: max-content;
            }
            &:hover{
                transition: 0.5s;
                box-shadow: 1px 1px 30px 0px rgb(222, 221, 221);
            }

            img{
                width: 50px;
            }
            h1{
                font-size: larger;
                text-align: center;
                text-transform: capitalize;
            }
            p{
                text-align: center;
                color: gray;
            }
            .clamp-lines{
                display: flex;
                flex-direction: column;
                align-items: center;
                transition: 0.5s;
            }
            button{
                background-color: transparent;
                border: none;
                cursor: pointer;
                text-transform: capitalize;
                color: $green;
                margin-top: 20px;
                border-bottom: 2px solid transparent;
                &:hover{
                    transition: 0.5s;
                    border-bottom: 2px solid $green;
                }
            }
        }
    }
    &.our-projects{
        .card-row{
            div{
                height: fit-content;
                @include mediaQueries.for-tablet-landscape-up{
                    // height: 400px;
                }
            }
        }
    }
    &#our-team{
        @include mediaQueries.for-phone-only{
            width: calc(100% - 30px);
            padding: 50px 15px;
            padding-bottom: 50px;
        }
    }
    &.our-clients{
        .card-row{

            div{

                img{
                    width: 200px;
                }
            }
        }
    }
}

.counter-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 50px 50px;
    width: calc(100% - 100px);
    @include mediaQueries.for-phone-only{
        width: calc(100% - 30px);
        padding: 50px 15px;
        padding-bottom: 0;
    }
    .counter-box{
        height: 150px;
        width: 20%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @include mediaQueries.for-tablet-landscape-up{
            width: 50%;
            margin-bottom: 50px;
            height: 130px;
        }
        @include mediaQueries.for-phone-only{
            width: 100%;
            height: 130px;
        }

        img{
            width: 60px;
        }

        div{
            display: flex;
            width: 100%;
            align-items: flex-end;
            .count-number{
                color: $green;
                font-size: 50px;
                font-weight: 600;
                margin-right: 20px;
                height: fit-content;
                @include mediaQueries.for-tablet-landscape-up{

                    font-size: 40px;
                    margin-right: 30px;
                }
                @include mediaQueries.for-phone-only{
                    font-size: 40px;
                    margin-right: 30px;
                }
            }

            h4{
                display: flex;
                flex-direction: column;
                margin: 0;
                color: #001b5e;
                text-transform: capitalize;
                font-size: xx-large;
                span{
                    opacity: 0.5;
                    font-size: medium;
                    color: black;
                }
            }
        }
    }
}